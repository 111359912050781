const DONE = 'done'
export default (key, ...props) => {
  const els = Array.from(document.querySelectorAll(`[${key}]`))
  const dict = {}
  els.forEach((el) => {
    const source = el.getAttribute(key)
    if (source === DONE) {
      //
    } else if (dict[source]) {
      Object.entries(dict[source]).forEach(([attr, value]) => {
        el.style[attr] = value // eslint-disable-line
      })
      el.setAttribute(key, DONE)
    } else {
      const ref = document.querySelector(source)
      if (!ref) return
      const style = props.reduce((all, one) => ({ ...all, [one]: getComputedStyle(ref)[one] }), {})
      dict[source] = style
      Object.entries(style).forEach(([attr, value]) => {
        el.style[attr] = value // eslint-disable-line
      })
      el.setAttribute(key, DONE)
    }
  })
}
