import { sizeRecipeCard } from './helpers/resize'
import deriveStyle from './helpers/deriveStyle'

export default (root) => {
  // DOM manip stuff
  sizeRecipeCard(root)

  // Add fallback for browsers that don't support object fit (IE11)
  if ('objectFit' in document.documentElement.style === false) {
    const objFits = root.querySelectorAll('.obj-fit')
    Array.prototype.forEach.call(objFits, (el) => {
      const parent = el.parentNode
      const img = el.getAttribute('src')
      el.setAttribute('style', 'opacity: 0;')
      parent.setAttribute('style', `background-image: url(${img}); background-size: cover; background-position: center`)
    })
  }

  deriveStyle('data-derive-font-from', 'fontFamily')
}
