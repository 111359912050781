
export const sizeRecipeCard = (root= null) => {

  if (!root) {
    return
  }

  // Change mv-no-js to js class
  root.className = root.className.replace(/\bmv-no-js\b/,'js')

  // Change class based on width
  function mvRecipeCardSizeClass() {

    // TODO: Localize size breakpoints so themes can adjust
    const sizes = {
      card: {
        xl: 900,
        lg: 630,
        md: 520,
        sm: 400,
      },
      times: {
        lg: 400,
        md: 300,
        sm: 250,
      }
    }

    const mvRecipeCardTimes = root.getElementsByClassName('mv-create-times')[0]
    if ( mvRecipeCardTimes ) {
      let mvRecipeTimesSize = 'xl'

      if ( mvRecipeCardTimes.scrollWidth < sizes.times.lg ) {
        mvRecipeTimesSize = 'lg'
      }

      if ( mvRecipeCardTimes.scrollWidth < sizes.times.md ) {
        mvRecipeTimesSize = 'md'
      }

      if ( mvRecipeCardTimes.scrollWidth < sizes.times.sm ) {
        mvRecipeTimesSize = 'sm'
      }

      mvRecipeCardTimes.classList.remove( 'mv-create-times-sm' )
      mvRecipeCardTimes.classList.remove( 'mv-create-times-md' )
      mvRecipeCardTimes.classList.remove( 'mv-create-times-lg' )
      mvRecipeCardTimes.classList.remove( 'mv-create-times-xl' )
      mvRecipeCardTimes.classList.add( 'mv-create-times-' + mvRecipeTimesSize )
    }

    let mvRecipeCardSize = 'xxl'

    if ( root.scrollWidth < sizes.card.xl ) {
      mvRecipeCardSize = 'xl';
    }

    if ( root.scrollWidth < sizes.card.lg ) {
      mvRecipeCardSize = 'lg';
    }

    if ( root.scrollWidth < sizes.card.md ) {
      mvRecipeCardSize = 'md';
    }

    if ( root.scrollWidth < sizes.card.sm ) {
      mvRecipeCardSize = 'sm';
    }

    root.classList.remove( 'mv-create-sm' )
    root.classList.remove( 'mv-create-md' )
    root.classList.remove( 'mv-create-lg' )
    root.classList.remove( 'mv-create-xl' )
    root.classList.remove( 'mv-create-xxl' )
    root.classList.add( 'mv-create-' + mvRecipeCardSize )
  }
  mvRecipeCardSizeClass()

  function mvResizeTimer() {
    window.clearTimeout(mvResizeTimer)
    window.mvResizeTimer = window.setTimeout(mvRecipeCardSizeClass, 100)
  }
  window.addEventListener('resize', mvResizeTimer, {passive: true})

  mvResizeTimer()
}

export default sizeRecipeCard
