import DomOperations from './dom'
import './style/card-all.scss'

__webpack_public_path__ = ENV === 'production'
  ? `${window.MV_CREATE_SETTINGS.__OPTIONS__.asset_url}/client/build/`
  : 'http://localhost:8080/'

window.mvCreate = {
  firstRun: false,
  hasRun: [],
  firstIo: () => {
    const stylesheet = document.querySelector('.mv-create-styles')
    if (stylesheet) {
      stylesheet.rel = 'stylesheet'
      stylesheet.type = 'text/css'
    }

    if (document.querySelector('.mv-art-link')) {
      document.querySelectorAll('.mv-art-link').forEach((item) => {
        const link = item.getAttribute('data-mv-create-link-href')
        const target = item.getAttribute('data-mv-create-link-target')
        if (link) {
          const img = item.querySelector('img')
          if (img) {
            img.addEventListener('click', (evt) => {
              evt.preventDefault()
              if (target === 'true') {
                window.open(link, '_blank')
              } else {
                window.location.href = link
              }
            }, false)
          }
          const btn = item.querySelector('button')
          if (btn) {
            btn.addEventListener('click', (evt) => {
              evt.preventDefault()
              if (target === 'true') {
                window.open(link, '_blank')
              } else {
                window.location.href = link
              }
            }, false)
          }
        }
      })
    }
  },
  init: (root = null ) => {
    if ( !window.mvCreate.firstRun ) {
      window.mvCreate.firstIo()
      window.mvCreate.firstRun = true
    }
    if (root && root.id) {
      if (window.mvCreate.hasRun.includes(root.id)) {
        return
      }
      window.mvCreate.hasRun.push(root.id)
    }

    // ensure we don't instantiate multiple comment/reviews apps
    if (!root) {
      Array.prototype.forEach.call(document.getElementsByClassName('mv-create-card'), (card) => {
        DomOperations(card)
      })

      if (!window.MV_REVIEWS_INIT) {
        window.MV_REVIEWS_INIT = true
        import(/* webpackChunkName: "mv-reviews" */ './Reviews').then(
          (module) => {
            module.default()
          }
        )
      }

      if (!window.MV_HANDS_FREE_INIT) {
        window.MV_HANDS_FREE_INIT = true
        import(/* webpackChunkName: "mv-handsfree" */ './HandsFree').then(
          (module) => {
            module.default()
          }
        )
      }
    }

    DomOperations(root)

    if (!window.MV_REVIEWS_INIT) {
      window.MV_REVIEWS_INIT = true
      import(/* webpackChunkName: "mv-reviews" */ './Reviews').then(
        (module) => {
          module.default()
        }
      )
    }

    if (!window.MV_HANDS_FREE_INIT) {
      window.MV_HANDS_FREE_INIT = true
      import(/* webpackChunkName: "mv-handsfree" */ './HandsFree').then(
        (module) => {
          module.default()
        }
      )
    }

    import(/* webpackChunkName: "mv-printbutton" */'./PrintButton').then(
      (module) => {
        module.default(document.querySelectorAll('[data-mv-print]'))
      }
    )
    import(/* webpackChunkName: "mv-pinbutton" */'./PinterestButton').then(
      (module) => {
        module.default(document.querySelectorAll('.mv-pinterest-btn'))
      }
    )

    return true
  },
}

if (window.IntersectionObserver) {

  const observer = new IntersectionObserver(((entries, self) => {
    // Get cards in current intersection
    const visibleCards = entries.filter(card => card.isIntersecting)

    // For each card, initialize and unobserve
    visibleCards.forEach(({ target }) => {
      window.mvCreate.init(target)
      self.unobserve(target)
    })
  }))

  // Get all cards and observe theme
  const cards = Array.from(document.getElementsByClassName('mv-create-card'))
  cards.forEach(card => observer.observe(card))

  const reviewsObserver = new IntersectionObserver(((entries, self) => {
    const items = entries.filter(item => item.isIntersecting)

    // For each card, initialize and unobserve
    items.forEach(({ target }) => {
      if (!window.MV_REVIEWS_INIT) {
        window.MV_REVIEWS_INIT = true
        import(/* webpackChunkName: "mv-reviews" */ './Reviews').then(
          (module) => {
            module.default()
          }
        )
      }
      self.unobserve(target)
    })
  }))

  const reviewsId = window.MV_CREATE_SETTINGS.__REVIEWS_DIV__ // eslint-disable-line
  const reviewsRoot = reviewsId && document.querySelector(reviewsId)
  if (reviewsRoot) {
    reviewsObserver.observe(reviewsRoot)
  }
} else {
  const delayListener = () => {
    window.mvCreate.init()
    document.removeEventListener('scroll', delayListener, false)
  }
  document.addEventListener('scroll', delayListener, {passive: true})
}

const loadCreateReviewsUIToComments = () => {
  const paginatedComments = window.location.href.includes('comment')
  if (paginatedComments) {
    if (!window.MV_REVIEWS_INIT) {
      window.MV_REVIEWS_INIT = true
      import(/* webpackChunkName: "mv-reviews" */ './Reviews').then(
        (module) => {
          window.mvCreate.firstIo()
          module.default()
        }
      )
    }
    return
  }

  let pageurl = window.location.href
  pageurl = pageurl.replace(window.location.hash, '')

  const commentsLink = document.querySelectorAll('[href="'+ pageurl + window.MV_CREATE_SETTINGS.__REVIEWS_DIV__ +'"]')
  if (commentsLink) {
    const reviewsClick = (evt) => {
      if (!window.MV_REVIEWS_INIT) {
        window.MV_REVIEWS_INIT = true
        import(/* webpackChunkName: "mv-reviews" */ './Reviews').then(
          (module) => {
            window.mvCreate.firstIo()
            module.default()
            evt.target.removeEventListener(reviewsClick)
          }
        )
      }
    }
    commentsLink.forEach(item => {
      item.addEventListener('click', reviewsClick)
    })
    return
  }
}

if ( window.MV_CREATE_SETTINGS.__REVIEWS_DIV__ ) {
  loadCreateReviewsUIToComments()
}
